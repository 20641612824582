<template>
  <card>
    <div class="d-flex point align-items-center justify-content-between">
      <div>
        <h4 class="title text-uppercase">{{ profile.title }}</h4>
        <p>
          {{ profile.name }}
        </p>
        <h6 v-if="profile.title != 'MD/CEO'">{{ profile.position }}</h6>
      </div>
      <div class="w-25 profile-image">
        <img :src="profile.photo" />
      </div>
    </div>
  </card>
</template>

<script>
export default {
  props: {
    profile: {
      type: Object,
      default: () => {
        return {};
      }
    }
  }
};
</script>

<style>
.point {
  cursor: pointer;
}
.profile-image {
  height: 80px;
}
.profile-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: top;
}
</style>
