<template>
  <card class="p-2" v-if="model">
    <h3 slot="header" class="title">
      {{ action }} {{ model.title || model.heading || model.name }}
    </h3>
    <div class="row">
      <div class="col-md-4">
        <div class="main-photo uploadable">
          <label
            for="photo_upload"
            class="upload d-flex align-items-center justify-content-center"
          >
            <p>Click to upload</p>
            <input
              @change="uploadPhoto"
              type="file"
              class="d-none"
              id="photo_upload"
            />
          </label>
          <img :src="photo" />
        </div>
      </div>
      <div class="col-md-8">
        <base-input label="Name" placeholder="Name" v-model="model.name">
        </base-input>
        <base-input
          v-if="model.designation == 'management'"
          label="Position"
          placeholder="eg Advisor, Business Reforms"
          v-model="model.position"
        >
        </base-input>
        <base-input class="mt-4">
          <label>Bio</label>
          <textarea
            rows="7"
            cols="80"
            class="form-control"
            v-model="model.profile"
          ></textarea>
        </base-input>
      </div>
    </div>
    <div class="row mt-4" v-if="photo2">
      <div class="col-md-12 d-flex align-items-center">
        <div class="profile-photo uploadable mr-4">
          <label
            for="photo_2_upload"
            class="upload d-flex align-items-center justify-content-center"
          >
            <p>Click to upload</p>
            <input
              @change="uploadThumbnail"
              type="file"
              class="d-none"
              id="photo_2_upload"
            />
          </label>
          <img :src="photo2" />
        </div>
        <div>
          <h6>
            Profile <br />
            Thumbnail
          </h6>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <base-button
        v-if="model.type == 'add'"
        @click="create"
        slot="footer"
        type="secondary"
        fill
        >Save</base-button
      >
      <base-button
        v-if="model.type != 'add'"
        @click="update"
        slot="footer"
        type="secondary"
        fill
        >Update</base-button
      >
      <base-button
        class="ml-4"
        v-if="!model.title && model.type != 'add'"
        @click="deleteProfile"
        slot="footer"
        type="warning"
        fill
        >Delete</base-button
      >
    </div>
  </card>
  <card v-else>
    <div
      class="d-flex flex-column align-items-center justify-content-center full user-select-none"
    >
      <i class="tim-icons icon-alert-circle-exc text"></i>
      <h4 class="text-uppercase font-weight-bold my-4">
        Select a Profile to view details
      </h4>
    </div>
  </card>
</template>
<script>
import store from "../../store";
export default {
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      photo1: null,
      photo2: null,
      newPhoto: null,
      newPhotoURL: null,
      newThumbnailURL: null,
    };
  },
  mounted() {
    this.photo1 = this.model?.photo;
    this.photo2 = this.model?.thumbnail;
  },
  computed: {
    photo: {
      get() {
        return this.photo1;
      },
      set(newValue) {
        this.photo1 = newValue;
      },
    },
    thumbnail: {
      get() {
        return this.photo2;
      },
      set(newValue) {
        this.photo2 = newValue;
      },
    },
    action() {
      return this.model.type == "add" ? "Add" : "Edit";
    },
  },
  watch: {
    model(newModel) {
      if (newModel.type == "add") {
        this.photo1 = this.newPhotoURL || newModel?.photo;
        this.photo2 = this.newThumbnailURL || newModel?.thumbnail;
      } else {
        this.photo1 = newModel?.photo;
        this.photo2 = newModel?.thumbnail;
      }
    },
  },
  methods: {
    uploadPhoto(event) {
      const photo = event.target.files[0];
      this.photo = URL.createObjectURL(photo);
      this.newPhotoURL = this.photo;

      if (this.model.type != "add") {
        const formData = new FormData();
        formData.append("id", this.model.id);
        if (this.model.designation == "NIEAC Member") {
          formData.append("photo", photo);
        } else {
          formData.append("photo_1", photo);
        }
        this.uploadImage(formData);
      } else {
        this.newPhoto = photo;
      }
    },
    uploadThumbnail(event) {
      const photo = event.target.files[0];
      this.photo2 = URL.createObjectURL(photo);
      this.newThumbnailURL = this.photo2;

      const formData = new FormData();
      formData.append("id", this.model.id);
      formData.append("photo_2", photo);

      this.uploadImage(formData);
    },
    create() {
      const formData = new FormData();
      if (this.newPhoto) {
        formData.append("photo", this.newPhoto);
        formData.append("bio", this.model.profile);
        formData.append("name", this.model.name);

        if (this.model.designation == "NIEAC Member") {
          formData.append("organization", "NIEAC");
          store
            .dispatch("createNIEACMember", formData)
            .then((response) => {
              this.$emit("clearModel");
              this.$notify({
                message: response.data.message,
                icon: "tim-icons icon-bell-55",
                horizontalAlign: "left",
                verticalAlign: "bottom",
                type: "success",
                timeout: 0,
              });
            })
            .catch((error) => {
              this.$notify({
                message: error.response.data.message,
                icon: "tim-icons icon-bell-55",
                horizontalAlign: "left",
                verticalAlign: "bottom",
                type: "warning",
                timeout: 0,
              });
            });
        } else if ((this.model.designation = "management")) {
          formData.append("position", this.model.position);
          store
            .dispatch("createTeamMember", formData)
            .then((response) => {
              this.$emit("clearModel");
              this.$notify({
                message: response.data.message,
                icon: "tim-icons icon-bell-55",
                horizontalAlign: "left",
                verticalAlign: "bottom",
                type: "success",
                timeout: 0,
              });
            })
            .catch((error) => {
              this.$notify({
                message: error.response.data.message,
                icon: "tim-icons icon-bell-55",
                horizontalAlign: "left",
                verticalAlign: "bottom",
                type: "warning",
                timeout: 0,
              });
            });
        }
      } else {
        alert("Please upload an image to continue");
      }
    },
    update() {
      if (this.model.title == "Governor") {
        store
          .dispatch("updateGovernor", this.model)
          .then((response) => {
            this.$emit("clearModel");
            this.$notify({
              message: response.data.message,
              icon: "tim-icons icon-bell-55",
              horizontalAlign: "left",
              verticalAlign: "bottom",
              type: "success",
              timeout: 0,
            });
          })
          .catch((error) => {
            this.$notify({
              message: error.response.data.message,
              icon: "tim-icons icon-bell-55",
              horizontalAlign: "left",
              verticalAlign: "bottom",
              type: "warning",
              timeout: 0,
            });
          });
      } else if (this.model.designation == "NIEAC Member") {
        store
          .dispatch("updateNIEACMember", this.model)
          .then((response) => {
            this.$emit("clearModel");
            this.$notify({
              message: response.data.message,
              icon: "tim-icons icon-bell-55",
              horizontalAlign: "left",
              verticalAlign: "bottom",
              type: "success",
              timeout: 0,
            });
          })
          .catch((error) => {
            this.$notify({
              message: error.response.data.message,
              icon: "tim-icons icon-bell-55",
              horizontalAlign: "left",
              verticalAlign: "bottom",
              type: "warning",
              timeout: 0,
            });
          });
      } else {
        store
          .dispatch("updateTeamMember", this.model)
          .then((response) => {
            this.$emit("clearModel");
            this.$notify({
              message: response.data.message,
              icon: "tim-icons icon-bell-55",
              horizontalAlign: "left",
              verticalAlign: "bottom",
              type: "success",
              timeout: 0,
            });
          })
          .catch((error) => {
            this.$notify({
              message: error.response.data.message,
              icon: "tim-icons icon-bell-55",
              horizontalAlign: "left",
              verticalAlign: "bottom",
              type: "warning",
              timeout: 0,
            });
          });
      }
    },
    deleteProfile() {
      const decision = window.confirm(
        `Are you sure you want to delete ${this.model.name}?`
      );
      if (decision) {
        if (this.model.designation == "NIEAC Member") {
          store
            .dispatch("deleteNIEACMember", this.model)
            .then((response) => {
              this.$emit("clearModel");
              this.$notify({
                message: response.data.message,
                icon: "tim-icons icon-bell-55",
                horizontalAlign: "left",
                verticalAlign: "bottom",
                type: "success",
                timeout: 0,
              });
            })
            .catch((error) => {
              this.$notify({
                message: error.response.data.message,
                icon: "tim-icons icon-bell-55",
                horizontalAlign: "left",
                verticalAlign: "bottom",
                type: "warning",
                timeout: 0,
              });
            });
        } else {
          store
            .dispatch("deleteTeamMember", this.model)
            .then((response) => {
              this.$emit("clearModel");
              this.$notify({
                message: response.data.message,
                icon: "tim-icons icon-bell-55",
                horizontalAlign: "left",
                verticalAlign: "bottom",
                type: "success",
                timeout: 0,
              });
            })
            .catch((error) => {
              this.$notify({
                message: error.response.data.message,
                icon: "tim-icons icon-bell-55",
                horizontalAlign: "left",
                verticalAlign: "bottom",
                type: "warning",
                timeout: 0,
              });
            });
        }
      }
    },
    uploadImage(data) {
      if (this.model.title == "Governor") {
        store
          .dispatch("updateGovernorPhoto", data)
          .then((response) => {
            this.$notify({
              message: response.data.message,
              icon: "tim-icons icon-bell-55",
              horizontalAlign: "left",
              verticalAlign: "bottom",
              type: "success",
              timeout: 0,
            });
          })
          .catch((error) => {
            this.$notify({
              message: error.response.data.message,
              icon: "tim-icons icon-bell-55",
              horizontalAlign: "left",
              verticalAlign: "bottom",
              type: "warning",
              timeout: 0,
            });
          });
      } else if (this.model.designation == "NIEAC Member") {
        store
          .dispatch("updateNIEACMemberPhoto", data)
          .then((response) => {
            this.$notify({
              message: response.data.message,
              icon: "tim-icons icon-bell-55",
              horizontalAlign: "left",
              verticalAlign: "bottom",
              type: "success",
              timeout: 0,
            });
          })
          .catch((error) => {
            this.$notify({
              message: error.response.data.message,
              icon: "tim-icons icon-bell-55",
              horizontalAlign: "left",
              verticalAlign: "bottom",
              type: "warning",
              timeout: 0,
            });
          });
      } else {
        store
          .dispatch("updateTeamMemberPhoto", data)
          .then((response) => {
            this.$notify({
              message: response.data.message,
              icon: "tim-icons icon-bell-55",
              horizontalAlign: "left",
              verticalAlign: "bottom",
              type: "success",
              timeout: 0,
            });
          })
          .catch((error) => {
            this.$notify({
              message: error.response.data.message,
              icon: "tim-icons icon-bell-55",
              horizontalAlign: "left",
              verticalAlign: "bottom",
              type: "warning",
              timeout: 0,
            });
          });
      }
    },
  },
};
</script>
<style>
.full {
  height: 50vh;
}
.text {
  font-size: 40px;
}
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.main-photo {
  width: 150px;
  height: 200px;
  margin: auto;
}

.profile-photo {
  width: 100px;
  height: 70px;
}
.profile-photo img {
  object-fit: contain;
}
</style>
