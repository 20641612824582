<template>
  <div class="row">
    <div class="col-md-5 full-height">
      <div
        @click="selectProfile(member)"
        v-for="member in managementTeam"
        :key="member.id"
      >
        <user-card :profile="member"></user-card>
      </div>
    </div>
    <div class="col-md-7" v-if="managementTeam">
      <div class="mb-4 d-flex align-items-center justify-content-between">
        <base-button @click="refresh" slot="footer" type="secondary" fill
          >Refresh</base-button
        >
        <base-button @click="add" slot="footer" type="secondary" fill
          >Add New</base-button
        >
      </div>
      <edit-profile-form @clearModel="clearModelData" :model="model">
      </edit-profile-form>
    </div>
  </div>
</template>
<script>
import EditProfileForm from "./Profile/EditProfileForm";
import UserCard from "./Profile/UserCard";
import store from "../store/index";
import nasidaLogo from "../assets/logo.svg";

export default {
  components: {
    EditProfileForm,
    UserCard,
  },
  data() {
    return {
      model: null,
    };
  },
  mounted() {
    store.dispatch("getManagementTeam");
  },
  methods: {
    selectProfile(profile) {
      this.model = { ...profile, designation: "management" };
    },
    add() {
      this.model = {
        type: "add",
        photo: nasidaLogo,
        designation: "management",
        heading: "Team Member",
      };
    },
    refresh() {
      store.dispatch("getManagementTeam");
    },
    clearModelData() {
      this.model = null;
    },
  },
  computed: {
    managementTeam() {
      return store.state.managementTeamModule.managementTeam;
    }
  },
};
</script>
<style></style>
