<template>
  <div class="row">
    <div class="col-md-5 full-height" v-if="governor.id && mdCEO.id">
      <div @click="selectProfile(governor)">
        <user-card :profile="governor"></user-card>
      </div>
      <div @click="selectProfile(mdCEO)">
        <user-card :profile="mdCEO"></user-card>
      </div>
    </div>
    <div class="col-md-7" v-if="governor.id && mdCEO.id">
      <edit-profile-form :model="model"> </edit-profile-form>
    </div>
  </div>
</template>
<script>
import EditProfileForm from "./Profile/EditProfileForm";
import UserCard from "./Profile/UserCard";
import store from "../store/index";

export default {
  components: {
    EditProfileForm,
    UserCard,
  },
  data() {
    return {
      model: null
    };
  },
  mounted() {
    store.dispatch("getGovernor");
    store.dispatch("getMDCEO");
  },
  methods: {
    selectProfile(profile) {
      this.model = profile;
    }
  },
  computed: {
    governor() {
      return { ...store.state.governorsModule.governor, title: "Governor" };
    },
    mdCEO() {
      return { ...store.state.managementTeamModule.mdCEO, title: "MD/CEO" };
    },
    loading() {
      return store.state.loadingModule.loading;
    }
  }
};
</script>
<style></style>
